@import "../../assets/scss/abstract/abstract";

.outerContainer {
  @include limitedContainer;
  width: 100%;
  @include mobile {
    margin: auto;
    width: 100%;
  }
}

/*
.searchParams {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
*/

.searchForm {
  display: grid;
  grid-template-columns: 3fr 3fr 2fr auto;
  grid-template-areas:
    "samelocation . ageselect ageselect"
    "locations datepicker timepicker searchbutton";

  @include tablet {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "samelocation samelocation samelocation . ageselect"
      "locations locations datepicker datepicker timepicker"
      ". . . . searchbutton";
    padding: 5px;
  }

  @include mobile {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "locations locations"
      "samelocation samelocation"
      "datepicker datepicker"
      "timepicker timepicker"
      "ageselect ageselect"
      "searchbutton searchbutton";
    padding: 5px;
  }
}

.sameLocationWrapper {
  grid-area: samelocation;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
}

.ageSelect {
  grid-area: ageselect;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;

  @include mobile {
    justify-content: flex-start;
  }
}

.datepickerWrapper {
  grid-area: datepicker;
  position: relative;
  @include mobile {
    position: static;
  }
  display: grid;
}

.timePickerWrapper {
  grid-area: timepicker;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.searchButtonWrapper {
  grid-area: searchbutton;
  display: grid;

  .searchButtonOuterContainer {
    display: flex;
    flex-direction: column;
  }

  .searchButton {
    height: 100%;
  }
}

.locationWrapper {
  grid-area: locations;
  position: static;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &.sameLocation {
    grid-template-columns: 2fr;
  }
}

.fullHeight {
  height: 100%;
}

.fullSize {
  padding: var(--yps-input-paddings);
  @include mobile {
    padding: var(--yps-input-mobile-paddings);
  }
}

.selected {
  &__locations {
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-width: 1px 0;
    border-color: var(--yps-input-border-color);
    button {
      border: 0;
    }
  }

  &__location {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    padding: 10px;
  }

  &__date {
    font-weight: 300;
  }

  &__edit {
    margin-left: auto;

    &_container {
      display: flex;
      padding: 10px;
      border-top: 1px solid var(--yps-input-border-color);
    }
  }
}

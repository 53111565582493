@import "../../assets/scss/abstract/abstract";

.singleOfferWidget {
  border-style: solid;
  border-width: var(--yps-offer-border-width);
  border-color: var(--yps-offer-border-color);
  background: var(--yps-bg-color);
  padding: 10px 10px 0 10px;
  margin-bottom: 10px;
  min-width: 200px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
  grid-template-areas:
    "offerHeading offerHeading"
    "offerPicture offerPicture"
    "offerEquip offerEquip"
    "offerPartner offerPartner"
    "splitter1 splitter1"
    "offerPrices offerPrices"
    "splitter2 splitter2"
    "offerMore offerButton"
    "offerDetails offerDetails";

  .offerDetails {
    grid-area: offerDetails;
    margin-top: 20px;
  }

  .offerCompare {
    display: none;
  }

  .underlineSplitter {
    border-bottom: 1px solid var(--yps-offer-border-color);
    height: 1px;

    &.underlineSplitter1 {
      grid-area: splitter1;
      margin: 10px -10px 10px;
    }

    &.underlineSplitter2 {
      grid-area: splitter2;
      margin: 10px -10px 0;
    }
  }

  .offerHeading {
    grid-area: offerHeading;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .carType {
      font-size: 18px;
      font-weight: 600;
      display: block;
      padding-left: 0;
    }

    .carNameContainer {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    .carName {
      font-weight: 400;
      margin-bottom: 0;
      padding-left: 0;
      white-space: nowrap;
    }

    .orSimiliar {
      margin-left: 5px;
      color: var(--yps-text-color-muted);
    }
  }

  .offerEquip {
    grid-area: offerEquip;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;

    padding-left: 0;
    > *:not(:last-child) {
      margin-right: 5px;
    }

    .carIcon {
      flex-direction: column;
      span {
        margin-left: 0;
        font-size: 0.6em;
      }
    }
  }

    .offerPicture {
      grid-area: offerPicture;
      display: flex;
      max-width: 200px;
      justify-content: center;
      align-items: center;
      position: relative;

      span {
        position: absolute;
        background-color: var(--yps-border-color-light);
        padding: 2px 5px;
        left: 0;
        top: 0;
        font-weight: 500;
        font-size: smaller;
      }

      img {
        &.noCarImage {
          max-height: 100px;
        }
      }
    }

    .offerPartner {
      grid-area: offerPartner;
      padding: var(--yps-offer-inside-paddings) 0;
      max-width: 200px;
      display: flex;
      flex-direction: column;

      justify-self: flex-start;
      padding: 0;
      justify-content: flex-end;

      &--rightBottom {
        align-self: flex-end;
        justify-self: flex-end;
        justify-content: flex-end;
        > span {
          justify-content: flex-end;
        }
        .offerAdvantages {
          justify-content: flex-end;
        }
      }
    }

    .offerPrices {
      grid-area: offerPrices;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      gap: 10px;

      &__pack {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      .offerPrice {
        font-weight: 700;
        line-height: 2rem;
        display: flex;
        align-items: baseline;
        justify-content: right;
        flex-wrap: wrap;

        span {
          &:nth-last-child(2) {
            font-size: 2.5em;
          }
          &:nth-last-child(3) {
            font-size: 2em;
          }

          &:nth-last-child(1) {
            margin-left: 5px;
            font-size: 2em;
          }
          color: var(--yps-featured-price);
        }

        &PerDay {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: baseline;
          color: var(--yps-text-color);
          font-weight: 500;
          span {
            &:nth-last-child(2) {
              font-size: 1em;
            }
            &:nth-last-child(3),
            &:nth-last-child(1) {
              margin-left: 3px;
              font-size: 0.9em;
            }
          }
        }

        &__exchanged {
          font-size: smaller;
        }
      }

      .pricePerDayContainer {
        margin-bottom: 5px;
      }

      .pricePerRentalContainer {
        color: var(--yps-text-color-muted);
        margin-bottom: 10px;
      }

      .pricePerDay {
        font-weight: bold;
        margin: 0 5px;
      }

      .pricePerRental {
        margin: 0 5px;
      }

      .pricePerDayLabel {
        font-size: small;
      }

      .pricePerRentalLabel {
        font-size: small;
      }
    }

    .offerMore {
      grid-area: offerMore;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .moreBtn {
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 10px;

        &:hover {
          text-decoration: none !important;
        }

        padding: 0;

        &__title {
          line-height: 20px;
          font-size: 1em;
          display: flex;
          align-items: center;
        }

        &__label {
          text-decoration: none;
          font-weight: 500;
          margin-right: 5px;
        }

        &__info {
          padding: 2px;
        }

        &__subtitle {
          display: block;
          font-size: 13px;
          line-height: 18px;
          white-space: nowrap;
          margin-left: 10px;
        }
      }
    }

    .offerButton {
      grid-area: offerButton;
      display: flex;
      justify-content: flex-end;
      align-self: flex-end;
    }

    .bookBtn {
      text-align: right;
      display: flex;
      align-items: center;
      padding: 5px 10px;

      margin: 0 -10px 0 0;

      &__total {
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
      }

      &__perDay {
        display: block;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        white-space: nowrap;
        display: flex;
        opacity: 0.7;
      }
    }

  .insuranceItem {
    &__listItem {
      &--warn {
        color: var(--yps-warn-color);
        border-color: var(--yps-warn-color);
      }

      &--danger {
        color: var(--yps-danger-color);
        border-color: var(--yps-danger-color);
      }
    }

    &__container {
      > span {
        display: inline-block;
        padding: 0 5px 0 0;
      }
    }

    &__description {
      display: block !important;
      color: var(--yps-text-color-extra-light);
      font-size: 11px;
    }

    &__liability {
      font-style: italic;
    }

    &__RCTInfo {
      font-style: italic;
    }

    &__excessInfo {
      font-weight: 500;

      &--warn {
        color: var(--yps-warn-color);
      }

      &--danger {
        color: var(--yps-danger-color);
      }
    }
  }

  .offerJson {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .insurancesDesc {
    &__set {
      border-bottom: 1px solid var(--yps-border-color-light);
      padding: 10px 0;

      display: flex;
      flex-direction: column;
    }

    &__caption {
      font-weight: 600;
    }

    &__desc {
      color: var(--yps-text-color-extra-light);
    }
  }

  .offerAdditionalInfos {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }

  .detailsOverlay {
    background: #fff;
    height: 100vh;
  }
}

@import "../../assets//scss/abstract/abstract";

.sidebar {
  padding: 0 1rem 1rem;
  @include mobile {
    padding: 0;
    border: 0;
  }

  @include tablet {
    padding: 0 10px 10px;
  }
}

.filterSidebar {
  @include mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background: var(--yps-bg-color);
    z-index: 10;
    overflow: auto;
  }

  &__labelWithQty {
    display: inline-block;
    line-height: 16px;
    flex: 1;
  }

  &__matchQty {
    margin: 0 0 0 10px;
    color: var(--yps-text-color-extra-light);
  }

  &__openToggle {
    z-index: 10;
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
  }
}

.filterBox {
  &__heading {
    padding: 10px 0;
    margin: 10px 0;
    border-bottom: 1px solid var(--yps-border-color);

    &__upper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 16px;
  }

  &__moreBtn {
    padding: 0 10px 0 0;
    font-size: 13px;

    > *:first-child {
      margin: 0 12px 0 3px;
    }
  }
}

.price {
  font-size: small;
  text-align: right;
  gap: 2px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: baseline;
}

.filtersDropdown {
  label {
    text-transform: capitalize;
    font-size: small;
  }

  &:not(:first-child) {
    label {
      margin-top: 10px;
    }
  }

  &__box {
    height: 52px;
  }

  &__inside {
    padding: 10px;
    font-size: 14px;
    font-weight: normal;
  }
}

.depositFilter,
.scoreFilter,
.equipFilter,
.stationFilter {
  display: flex;
  align-items: center;
  padding: 5px 0;
  > span {
    display: flex;
    align-items: center;
  }

  &__withUnderlabel {
    flex-direction: column;
    > span {
      gap: 5px;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }

  div {
    display: flex;
    gap: 2px;
  }

  &__label {
    margin-left: 10px;
    text-align: left;
  }

  &__underlabel {
    font-size: smaller;
    font-style: italic;
    margin: 5px 0 0 5px;
    color: var(--yps-text-color-muted);
    line-height: 125%;
    text-align: left;
  }
}

.scoreFilter {
  &__label {
    margin-left: 4px;
  }
}

.depositFilterPriceLabel {
  display: inline-block !important;
  div {
    display: inline-block;
    margin-left: 3px;
  }
}

.usedFilters {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  &__item {
    background-color: var(--yps-bg-color);
    padding: 0;
    color: var(--yps-text-color);
    margin-right: var(--yps-default-space);
    margin-bottom: var(--yps-default-space);

    button {
      display: flex;
      align-items: center;
    }
  }

  &__label {
    color: var(--yps-text-color-extra-light);
    margin-right: 5px;
  }

  &__value {
    font-weight: 500;
  }
}

.clearBtn {
  font-weight: 600;
  cursor: pointer;
  padding: 4px var(--yps-default-space) !important;
}

@import '../../assets//scss/abstract/abstract';

.blockMessage {
  margin: 40px auto;
  width: 500px;
  @include mobile {
    width: auto;
  }
  max-width: 90%;
  text-align: center;

  &__title {
    font-size: 28px;
    line-height: 28px;
    font-weight: 300;
    margin: 0;
  }

  &__buttons {
    margin-top: 30px;
  }
 }
@import "../../assets/scss/abstract/abstract";

.categorySlider {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-bottom: var(--yps-default-space);

  &__item {
    background: var(--yps-bg-color);
    border: 1px solid transparent;
    border-radius: var(--yps-box-radius-slider-offer);
    &--active {
      border-color: var(--swiper-active-color);
    }
  }


  &__button {
    border-radius: var(--yps-box-radius-slider-offer);
    padding: 0;
    border-color: var(--yps-border-color-light) !important;
    width: 100%;
  }

  &__category {
    font-weight: 600;
    font-size: 1.1em;
    padding: 10px 0 0;
  }

  &__price {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px;
    font-size: 1.1em;
    font-weight: 600;
  }
}

.offerPicture {
  &__img {
    object-fit: contain;
  }
}
@import "../../assets/scss/abstract/abstract";

/* ---------------------------------------------------------------- INPUT AND OUTER CONTAINER --- */

.container {
  display: grid;
  position: relative;
  transition: all 0.3s ease-out;
  top: 0;
  left: 0;

  &--highlight {
    z-index: 10;

    @include notMobile {
      filter: drop-shadow(0 0px 10px var(--yps-input-focus-shadow-color));
    }
  }
}

.rangeContainer {
  grid-template-columns: 50% 50%;

  @include mobile {
    grid-template-columns: 1fr 1fr;
  }

  > :first-child {
    /*
    border-right-width: 0;

    @include mobile {
      border-right-width: 1px;
      border-top: 0;
    }
    */
  }
}

.inputContainer {
  position: relative;
  outline-color: var(--yps-input-outline-color);

  @include mobile {
    // border-bottom-width: 0;
  }
}

.input {
  cursor: pointer;
}

.weekdayLabel {
  color: var(--yps-text-color-extra-light);

  @include tablet {
    display: none;
  }
}

/* ---------------------------------------------------------------------- DATEPICKER DROPDOWN --- */

.pickerSelection {
  color: var(--yps-text-color);
  @include mobile {
    color: var(--yps-bg-color);
  }
  text-align: center;
  padding: 10px 0;
  margin-bottom: 0;

  strong {
    font-weight: 500;

  }

  span {
    white-space: nowrap;
  }

  &__current {
    @include notMobile {
      color: var(--yps-primary-color);
    }
  }
}

.pickerWrapper {
  position: absolute;
  top: 100%;
  margin-top: 1px;
  background-color: var(--yps-bg-color);
  z-index: 5;
  box-shadow: var(--yps-overlay-shadow);

  @include mobile {
    height: 100vh;
    width: 100vw;
    top: 0;
    margin-top: 0;
  }
}

.outerContainer {
  position: absolute;
  top: 0;
  left: 0;
}

.datepickerWrapperOuter {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000 !important;
  position: absolute;
}

.datepickerWrapperContent {
  overflow: unset !important;
}

.datepickerOpener {
  width: 100%;
  font-weight: 600;
}

@import "../../assets/scss/abstract/abstract";

/* ---------------------------------------------------------------------- INPUT AND CONTAINERS--- */

.outerContainer {
  display: flex;
  transition: all 0.3s ease-out;
  flex-direction: column;

  &--focused {
    filter: drop-shadow(0 0px 10px var(--yps-input-focus-shadow-color));
  }
}

.innerContainer {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  outline-color: var(--yps-input-outline-color);



  input {
    width: 100%;
    line-height: 28px;
    padding: var(--yps-input-paddings);
    @include mobile {
      padding: var(--yps-input-mobile-paddings);
    }
  }

  &--error {
    outline-style: auto!important;
    z-index: 10;
    outline-color: var(--yps-infobox-border-error)!important;

    input {
      color: var(--yps-infobox-text-error)!important;
    }
  }
}

.spinner {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
  margin: 0;
}

.resultsContainer {
  position: absolute;
  min-width: 100%;
  overflow: hidden;
  top: 100%;
  margin-top: 1px;
  left: 0;
  z-index: 10;
  box-shadow: var(--yps-overlay-shadow);
}

.resultsList {
  background-color: var(--yps-suggester-bg-color);
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;

  li:first-child button {
    border-top: none;
  }
}

/* ------------------------------------------------------------------------------- SINGLE ROW --- */

.locationBtn {
  background-color: var(--yps-suggester-bg-color);
  border-width: 1px 0 0;
  border-color: var(--yps-input-border-color);
  appearance: none;
  text-align: left;
  display: block;
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;

  &--level-1 {
    padding-left: 45px;
  }

  .locationIconContainer {
    width: 20px;
    margin-right: var(--yps-default-space);
    color: var(--yps-suggester-location-icon-color);

    i {
      font-size: 18px;

      &:before {
        margin: 0;
      }
    }
  }

  .locationName {
    display: block;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;

    &--hover {
      color: var(--yps-suggester-text-color-secondary);
    }

    i {
      font-weight: normal;
      font-style: normal;
    }
  }

  .locationDetails {
    color: var(--yps-suggester-location-details-color);
    font-size: 12px;
    white-space: nowrap;
  }

  &--highlight {
    background-color: var(--yps-suggester-bg-color-secondary);
    color: var(--yps-suggester-text-color-secondary);
    .locationDetails {
      color: var(--yps-suggester-location-details-color-secondary);
    }
    .locationIconContainer {
      color: var(--yps-suggester-location-icon-color-secondary);
    }
  }

  &--inactive {
    cursor: initial;
  }
}

.outerContainer {
  position: absolute;
  top: 0;
  left: 0;
  outline-color: var(--yps-input-outline-color);
}

.suggesterWrapperOuter {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000 !important;
  position: absolute;
}

.suggesterWrapperContent {
  overflow: unset !important;
}

.suggesterOpener {
  width: 100%;
  font-weight: 600;
  @include mobile {
    border: 0;
  }
}
